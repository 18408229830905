import request from '@/api/request'

//PC企业端-企业详情
export function companyInfo(data) {
    return request({
        url: '/company/company/info',
        method: 'GET',
        params:data
    })
}

//PC企业端-企业信息
export function companyDetail(data) {
    return request({
        url: '/company/company/detail',
        method: 'GET',
        params:data
    })
}

//PC企业端-编辑企业
export function companyUpdate(data) {
    return request({
        url: '/company/company/update',
        method: 'POST',
        data
    })
    
}
//PC企业端-管理员列表
export function companyPowerList(data) {
    return request({
        url: '/company/company/power/list',
        method: 'GET',
        params:data
    })
}


//PC企业端-管理员详情
export function companyPowerInfo(data) {
    return request({
        url: '/company/company/power/info',
        method: 'GET',
        params:data
    })
}

///PC企业端-添加管理员
export function companyPowerAdd(data) {
    return request({
        url: '/company/company/power/add',
        method: 'POST',
        data
    })
    
}


///PC企业端-管理员修改
export function companyPowerUpdate(data) {
    return request({
        url: '/company/company/power/update',
        method: 'POST',
        data
    })
    
}

//PC企业端-管理员删除
export function companyPowerDel(data) {
    return request({
        url: '/company/company/power/del',
        method: 'POST',
        data
    })
    
}


///PC企业端-未设置管理员列表
export function companyPowerGetUser(data) {
    return request({
        url: '/company/company/power/get/user',
        method: 'GET',
        params:data

    })
    
}

//PC企业端-权限节点列表
export function companyPowerNodes(data) {
    return request({
        url: '/company/company/power/nodelist',
         method: 'GET',
        params:data
    })
    
}










