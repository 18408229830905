<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="添加管理员"
    width="500px"
    
  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px">
        <el-form-item label="管理员" prop="user_app_id" >  
           <el-select
                v-model="formData.user_app_id" 
                placeholder="请选择管理员"
                clearable
                filterable
              >
              <el-option
                v-for="item in userList"
                :key="item.user_app_id"
                :label="item.alias_name"
                :value="item.user_app_id"
              />
          </el-select>
        </el-form-item>

        <el-form-item label="管理角色：" prop="identity" >
          <el-select v-model="formData.identity" placeholder="请选择">
            <el-option label="督查" value="1"></el-option>
            <el-option label="客服" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="管理范围：" prop="all_num" >
          全企业
          <p>具有对所有的应用发消息和管理的权限</p>
        </el-form-item>

     
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>


  </el-dialog>
</template>

<script>

import { companyPowerGetUser,companyPowerAdd,companyPowerUpdate,companyPowerDel} from "@/api/manage/index";

export default {

  data() {
    return {
      visible: false,
      formData: {
        user_app_id:"",
        identity: "",
      },
       rules: {
        user_app_id: [
              {required: true, message: '请选择管理员', trigger: 'change'}
          ],
          identity: [
              {required: true, message: '请选择管理角色', trigger: 'blur'}
          ],
        
       },
       userList:[]
    };
  },

 
  mounted() {
    console.log("asdasdasd")

},
  
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;

    },


    init() {
      this.visible = true;
      this.initData();
    },


    
    initData(){
        var that = this;
       
        companyPowerGetUser({}).then((response) => {
          console.log(response);
          if(response.code==200){
            that.userList = response.data;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
    },
    
    submit(){
      var that=this;
     

      this.$refs["form"].validate((valid) => {
          if (valid) {
            companyPowerAdd(that.formData).then((response) => {
              console.log(response);
              if(response.code==200){
                this.$message({
                   message: "提交成功",
                   type: 'success'

                });
                that.$emit('refresh');
                that.visible = false;
              
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
          } 
        });

    },

    
  },

 
};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
