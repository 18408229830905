<template>
  <div class="power" style="display: flex">
    <div class="power-left">
      <div class="p-left-top">
        <div style="display: flex">
            <p >管理员</p>
            <!--<p style="margin: 10px" @click="mangers">管理组</p>-->
        </div>
        <span style="font-size: 30px;cursor: pointer;" @click="addPower">+</span>
      </div>
      <div>
        <ul>
          <li
            v-for="(item,index ) in pageProps.records" :key="index"
            class="manger"
            :class="item.active == 1 ? 'change' : ''"
            @click="detailInfo(item.user_app_id)"
          >
            <p>{{ item.alias_name }}</p>
            <!--1督查 2客服-->
            <p >{{ item.identity==1?"督查":"客服" }}</p>
          </li>
         
        </ul>

        <!--
        <ul v-if="this.num == 1">
          <li style="margin-left: -27px">
            <p :class="num == 1 ? 'change' : ''" style="padding-left: 6px">
              超级管理组
            </p>
          </li>
        </ul>
-->
      </div>
    </div>
    <!--  -->
    <div  class="power-right" >
        <div class="p-manger" v-if="isedit==false">
          <div class="p-right-top">
            <p>管理详情</p>
          </div>

          <div class="p-right-edit" >
            <div class="edit">
              <span @click="isedit=true">编辑</span>
            </div>
            <div class="edit">
              <span @click="delPower">删除</span>
            </div>
          </div>

          <div style=" display: flex;  border-bottom: 1px solid #999;">
            <div >
              <img :src="powerInfo.user_avatar"  class="avatar"/>
            </div>
            <div class="name-major">
              <span>{{ powerInfo.alias_name }}</span>
              <span>{{ powerInfo.identity==1?"督查":"客服" }}</span>
            </div>
          </div>

          <div  class="p-right-model"   >
            <div>
              <span>管理范围:</span>
            </div>
            <div>
              <span>全企业</span>
            </div>
          </div>

          <div>
            <p style="padding: 10px 20px 0px 10px ;">权限信息</p>
            <div class="p-right-model"   >
              <div>
                <span>通讯录权限:</span>
              </div>
              <div>
                <p>  </p>
                <span>具有对所有的应用发消息和管理的权限</span>
              </div>
            </div>
          </div>
          
          <div  class="p-right-model" >
            <div>
              <span>应用权限:</span>
            </div>
            <div>
              <span>具有绑定新的硬件设备的权限</span>
            </div>
          </div>
          <div  class="p-right-model"   >
            <div>
              <span>设备管理权限:</span>
            </div>
            <div>
              <p>具有绑定新的硬件设备的权限</p>
              <p>具有所有设备的管理权限</p>
            </div>
          </div>

        </div>
        <div class="p-manger" v-if="isedit==true">
          <div class="p-right-top">
            <p>编辑管理员</p>
          </div>
          <div  class="p-right-model"   >
            <div>
              <span>管理员:</span>
            </div>
            <div>
              <span>{{ powerInfo.alias_name }}</span>
            </div>
          </div>

          <div  class="p-right-model"   >
            <div>
              <span>任命身份:</span>
            </div>
            <div>
              <el-radio v-model="powerInfo.identity" :label="1" style="margin-right:20px">督查</el-radio>
              <el-radio v-model="powerInfo.identity" :label="2">客服</el-radio>
            </div>
          </div>

          <div>
            <p style="padding: 10px 20px 0px 10px ;">权限信息</p>
            <div class="p-right-model"   >
              <div>
                <span>通讯录权限:</span>
              </div>
              <div>
                <p>  </p>
                <span>具有对所有的应用发消息和管理的权限</span>
              </div>
            </div>
          </div>
          
          <div  class="p-right-model" >
            <div>
              <span>应用权限:</span>
            </div>
            <div>
              <span>具有绑定新的硬件设备的权限</span>
            </div>
          </div>
          <div  class="p-right-model"   >
            <div>
              <span>设备管理权限:</span>
            </div>
            <div>
              <p>具有绑定新的硬件设备的权限</p>
              <p>具有所有设备的管理权限</p>
            </div>
          </div>

          <div style="padding :10px">

            <el-button  @click="isedit=false">取消</el-button>
            <el-button   type="primary" @click="upadtePower">提交</el-button>
          </div>

        </div>

    </div>
    <!--  -->
    <power-add-dialog ref="powerAddDialog" @refresh="fetchData"></power-add-dialog>

  </div>
</template>

<script>
import { companyPowerList,companyPowerInfo,companyPowerAdd,companyPowerUpdate,companyPowerDel} from "@/api/manage/index";

import powerAddDialog from "@/views/manage/power-add-dialog.vue";


export default {
  components: { powerAddDialog},

  data() {
    return {

      pageProps: {
        records:[],
        total: 0,
        pageSize: 10,
        pageNum: 1
      },

      searchFormData:{
        page:1,
        limit:10
      },
      powerList:[],
      powerInfo:{
        user_app_id:"",
        identity:""
      },
      isedit:false,
    };
  },

  mounted(){
    this.fetchData();
  },
  methods: {

    fetchData(){
        var that = this;
        that.searchFormData.page=that.pageProps.pageNum;
        that.searchFormData.limit=that.pageProps.pageSize;

        companyPowerList(that.searchFormData).then((response) => {
          console.log(response);
          if(response.code==200){
            that.pageProps.records = response.data.data;
            that.pageProps.total = response.data.total;
            that.detailInfo(that.pageProps.records[0].user_app_id);
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
    },




    // 当前页变更
    handleCurrentChange(num) {
      this.pageProps.pageNum = num
      this.fetchData()
    },


    // 每页显示页面数变更
    handleSizeChange(size) {
      this.pageProps.pageSize = size
      this.fetchData()
    },
    back(){
      this.$router.go(-1);
    },


    detailInfo(user_id) {
        var that = this;
        this.pageProps.records.forEach(item => {
          console.log(item);
          if(item.user_app_id==user_id){
              item.active=1
          }else{
            item.active=0
          }
        });
        this.$forceUpdate();
      

        companyPowerInfo({"user_app_id":user_id}).then((response) => {
          console.log(response);
          if(response.code==200){
            that.powerInfo = response.data;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
      
    },

    delPower(){
      var that=this;
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
            const user_app_id	=that.powerInfo.user_app_id;
            companyPowerDel({user_app_id:user_app_id}).then((response) => {
              console.log(response);
              if(response.code==200){
                  that.fetchData();
                
              }
            });
          
        }).catch(() => {
        
        });
    },

    upadtePower(){
      var that = this;
        companyPowerUpdate({"user_app_id":that.powerInfo.user_app_id,"identity":that.powerInfo.identity})
        .then((response) => {
          console.log(response);
          if(response.code==200){
            that.$message.success(response.msg?response.msg:"修改成功");
            that.isedit=false;
            that.fetchData()

          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
        });
    },

    addPower(){
      this.$refs.powerAddDialog.init();
    },
  },
};
</script>

<style lang="scss" scoped>

.manger {
  display: flex;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 6px;
}
.change {
  background-color: #4c71db;
  color: white;
}
.power-left {
  border-right: 1px solid #999;
  min-height: 600px;
  line-height: 30px;
  padding: 10px;
  width: 150px;
  li{
    font-size:12px
  }
}
.p-left-top {
  border-bottom: 1px solid #999;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.power-right {
  width: 768px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}
.p-right-top {
  border-bottom: 1px solid #999;
  padding: 5px 10px;
  height: 40px;
  width: 768px;
  
  display: flex;
  align-items: center;

  p{
   font-size: 16px; 
   line-height: 40px;
   font-weight: bold;
  }
}
.p-right-edit {
  border-bottom: 1px solid #999;
  width: 768px;
  display: flex;
  padding: 10px 10px;
}
.edit {
  background-color: #4c71db;
  color: white;
  border-radius: 5px;
  height: 25px;
  margin-right: 20px;
  font-size: 12px;
  cursor: pointer;
  span{
    margin: 4px; line-height: 25px
  }
}
.name-major {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;


}

.avatar{
  height: 60px; border-radius: 5px;margin: 20px 30px
}
.p-right-model {
  border-bottom: 1px solid #999;
  display: flex;
  font-size: 12px;

  div{
    padding:10px
  }
}
.p-right-model2 {
  border-bottom: 1px solid #999;
  height: 100px;
  width: 768px;
  display: flex;
}
.input {
  width: 240px;
  height: 36px;
  background: #f6f6f6;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 0 10px;
  outline: none;
}
.power {
  padding: 5px;
  background-color: white;
}
</style>
